import { TextField, InputAdornment, IconButton, SvgIcon, useTheme } from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as SendIcon } from './send.svg';

export const UserInput = ({
  isLoading,
  label,
  onSend,
  regainFocus,
}: {
  isLoading: boolean;
  label?: string;
  onSend: (text: string) => Promise<void>;
  regainFocus: boolean;
}) => {
  const { direction } = useTheme();
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSubmit = () => {
    onSend(text);
    setText('');
  };
  useEffect(() => {
    if (!isLoading && regainFocus) {
      inputRef.current?.focus();
    }
  }, [isLoading, regainFocus]);
  const iconStyle =
    direction === 'rtl'
      ? { transform: 'none', marginTop: '3px' }
      : { transform: 'rotate(180deg)', marginBottom: '3px' };
  return (
    <TextField
      variant="outlined"
      disabled={isLoading}
      sx={
        isLoading
          ? {
              animation: 'fade 1s infinite',
              '@keyframes fade': {
                '0%': {
                  opacity: 1,
                },
                '50%': {
                  opacity: 0.5,
                },
                '100%': {
                  opacity: 1,
                },
              },
            }
          : {}
      }
      fullWidth
      size="small"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          handleSubmit();
        }
      }}
      placeholder={label}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disableRipple
              size="small"
              sx={
                isLoading
                  ? {
                      animation: 'fade 1s infinite',
                      '@keyframes fade': {
                        '0%': {
                          opacity: 1,
                        },
                        '50%': {
                          opacity: 0.5,
                        },
                        '100%': {
                          opacity: 1,
                        },
                      },
                    }
                  : {}
              }
              aria-label="toggle password visibility" // TODO add dynamic text
              onClick={handleSubmit}
              edge="end"
              color="primary"
              disabled={!text || isLoading}
            >
              <SvgIcon color={!text ? 'disabled' : 'primary'} fontSize="small" style={iconStyle}>
                <SendIcon />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
